<template>
  <div class="card card-custom">
    <div class="card-header">
      <div class="card-title">
        <span class="card-icon">
          <i class="flaticon2-chat-1 text-primary"></i>
        </span>
        <h3 class="card-label font-poppins">All Order Items</h3>
      </div>
    </div>
    <div class="card-body p-0">
      <div class="row my-5 px-2">
        <div class="col-sm-12">
      
          <v-data-table
            :headers="headers"
            :items="items"
            :sort-desc="[false, true]"
            class="elevation-1"
          >
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="edit(item.id)">
                mdi-pencil
              </v-icon>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "businesses",
  data() {
    return {
      dialog: false,
      singleSelect: false,
      selected: [],
      headers: [
        {
          text: "Order",
          align: "left",
          sortable: false,
          value: "order_id",
        },
        {
          text: "Order Date",
          align: "left",
          sortable: false,
          value: "orders.created_at",
        },
        {
          text: "Product",
          align: "left",
          sortable: false,
          value: "products.name",
        },
        {
          text: "Quantity",
          align: "left",
          sortable: false,
          value: "quantity",
        },
        {
          text: "Toppings",
          align: "left",
          sortable: false,
          value: "toppings",
        },
        {
          text: "Properties",
          align: "left",
          sortable: false,
          value: "properties",
        },
        {
          text: "Total Amount",
          align: "left",
          sortable: false,
          value: "amount",
        },
      ],
      items: [],
      business : "",
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Customer Order Items" }]);
    this.loadOrders()
  },
  methods: {
    loadOrders() {
      ApiService.post("admin/order-items" , {
        business_id : this.business,
      }).then(({ data }) => {
        this.items = data;
      });
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
  },
};
</script>
